import type { ImgHTMLAttributes, ReactNode } from 'react';
import { forwardRef, useRef } from 'react';
import { isNonNullish } from 'remeda';
import type { Merge, Simplify } from 'type-fest';

import type { UseImageProps } from '../../hooks/use-image/index.js';
import { useImage } from '../../hooks/use-image/index.js';
import type { Component } from '../../types.js';
import { type BoxProps, Box, useStyleProps } from '../box/index.js';

type ImageAttributes = ImgHTMLAttributes<HTMLImageElement>;

export type ImageProps = Simplify<
  UseImageProps &
    Merge<ImageAttributes, BoxProps> & {
      alt?: string;
      children?: never;
      fallback?: ReactNode;
      lazy?: boolean;
    }
>;

export const Image = forwardRef<HTMLImageElement, ImageProps>(function Image(
  {
    alt,
    aspectRatio,
    backgroundColor = { dark: '$gray-600', light: '$brand-white' },
    fallback,
    lazy = false,
    src,
    srcSet,
    sizes,
    objectFit,
    css,
    ...props
  },
  _ref,
) {
  const ref = useRef<HTMLImageElement | null>(null);
  const { htmlAttributes, cssProperties } = useStyleProps(props);
  const { height, id, status, width } = useImage(ref);
  const ratio = aspectRatio ?? (height > 0 ? width / height : undefined);

  return (
    <Box
      aspectRatio={ratio}
      backgroundColor={backgroundColor}
      data-test="image-container"
      display="inline-block"
      key={id}
      overflow="hidden"
      position="relative"
      {...cssProperties}
    >
      <Box
        aria-hidden={status === 'succeeded'}
        backgroundColor={backgroundColor}
        bottom={0}
        data-test="image-fallback-container"
        display="grid"
        height="100%"
        left={0}
        opacity={status === 'succeeded' ? 0 : 1}
        placeItems="center"
        position="absolute"
        right={0}
        top={0}
        transition="opacity 300ms ease"
        width="100%"
      >
        {status === 'failed' || lazy ? fallback : null}
      </Box>
      {status !== 'failed' && isNonNullish(src) ?
        <Box
          alt={alt}
          aspectRatio={ratio}
          crossOrigin="anonymous"
          css={css}
          data-status={status}
          height="auto"
          loading="lazy"
          objectFit={objectFit ?? 'cover'}
          opacity={status === 'succeeded' || !lazy ? 1 : 0}
          position="relative"
          ref={ref}
          sizes={sizes}
          src={src}
          srcSet={srcSet}
          transition="opacity 300ms ease"
          width="100%"
          zIndex="$1"
          {...htmlAttributes}
          as="img"
        />
      : null}
    </Box>
  );
}) as Component<'img', ImageProps>;
